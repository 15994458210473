import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `AI Design Agency`,
    text: "AI UI UX Design",
    content: "Our AI design agency offers comprehensive AI design solutions and integrates the latest advancements in AI to enhance every aspect of your online presence, ensuring a seamless and engaging user experience. Partner with us to set your business apart with innovative AI design services.",
    list: [
      'Cutting-edge AI Integration',
      'User-friendly Interfaces',
      'Tailored Solutions'
    ]
  };
  const ourData = {
    title: `Client Insights On Our <span class='h1-span'>AI Design Services</span>`,
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Achieve Excellence With Our  <span class='h1-span'>AI Web Design Agency</span>",
    content: [
      {
        title: "7+ Years of Expertise",
        text: "Our 7+ years of experience positions us as AI industry leaders. With deep AI expertise, we provide innovative AI design solutions that advance the field.",
      },
      {
        title: "25% Improved Efficiency",
        text: "Through our strategic approach, we've achieved a 25% improvement in operational efficiency for clients by optimizing workflows and leveraging technologies.",
      },
      {
        title: "30% Reduced Loading Time",
        text: "Our focus on performance optimization has led to a 30% reduction in loading time for client websites and apps by optimizing code and leveraging caching.",
      },
      {
        title: "95% Client Satisfaction Rate",
        text: "With a 95% client satisfaction rate, we prioritize communication, deliver exceptional results, and provide unparalleled support to exceed client expectations.",
      },
    ],
  };
  const commonContent1 = {
    title: "Redefine Efficiency With Our AI Web Design Services",
    content: [
      {
        title: "Personalized User Experiences",
        text: "We craft tailored user experiences by understanding your user preferences and behaviour and create interfaces that drive engagement and loyalty.",
      },
      {
        title: "Optimized Conversion Strategies",
        text: "From lead generation to customer acquisition, our data-driven approach maximizes conversion rates, drive growth and profitability in this competitive landscape.",
      },
      {
        title: "Data-Backed Decision Making",
        text: "Our AI web design agency empowers you with actionable insights derived from AI-driven analytics helping you make informed decisions and stay ahead in the industry.",
      },
      {
        title: "Cutting-Edge Technologies",
        text: "From AI-powered chatbots to immersive AI interfaces, we create groundbreaking digital experiences that position you as a leader in the AI space.",
      },
      {
        title: "Scalable Solutions",
        text: "Whether you’re a startup or established business, our web design solutions are designed to scale with your AI initiatives, accommodating growth and innovation.",
      },
      {
        title: "Continuous AI Innovation",
        text: "We stay at the forefront of AI innovation, continuously researching and implementing the latest advancements in AI technology, positioning you at top in the AI industry.",
      },
    ],
  };
  const Questions = [
    "Can you explain the process of working with your AI design agency?",
    "What results can we expect from implementing AI in our website design?",
    "Does your AI design agency have experience in our industry niche?",
    "What sets your AI design agency apart from traditional design firms?",
    "Can you provide examples of successfully completed AI projects?"
  ];
  const faqData = [
    {
        para: [
            `Our process typically begins with a consultation to understand your goals, target audience, and specific requirements. From there, we develop a customized strategy and design concept, incorporating AI-driven insights and solutions.`,
            `Throughout the project, we maintain open communication, provide regular updates, and iterate based on feedback to ensure your satisfaction.`
        ]
    },
    {
      para: [
        `By implementing AI in your website design, you can expect to see improvements in user engagement, conversion rates, and overall performance.`,
        `AI-driven personalization, optimization, and automation can lead to enhanced user experiences, higher customer satisfaction, and increased ROI for your business.`
      ],
    },
    {
      para: [
        `Yes, our AI design agency has experience working with clients across various industry niches, including e-commerce, healthcare, finance, technology, and more.`,
        `We tailor our AI design solutions to meet the unique challenges and requirements of each industry, delivering impactful results that align with your business objectives.`
      ]
    },
    {
      para: [
        `Unlike traditional design firms, our AI design agency specializes in harnessing the power of artificial intelligence to create intelligent and dynamic website experiences.`,
        `We combine human creativity with AI-driven insights to deliver innovative solutions that drive engagement and achieve business objectives effectively.`
      ],
    },
    {
        para: [
          `Certainly! Our AI design agency has a portfolio of successful projects across various industries, showcasing our expertise in AI website design, AI-driven user experiences, and AI-powered marketing solutions.`,
          `We'd be happy to share case studies and examples of our work upon request. <a href='/contact-us/' title='Contact us' target='_blank'>Contact Us</a>`
        ],
      }
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                "@type": "Question",
                "name": "Can you explain the process of working with your AI design agency?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our process typically begins with a consultation to understand your goals, target audience, and specific requirements. From there, we develop a customized strategy and design concept, incorporating AI-driven insights and solutions. 
            Throughout the project, we maintain open communication, provide regular updates, and iterate based on feedback to ensure your satisfaction."
                }
                },{
                "@type": "Question",
                "name": "What results can we expect from implementing AI in our website design?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "By implementing AI in your website design, you can expect to see improvements in user engagement, conversion rates, and overall performance. 
            AI-driven personalization, optimization, and automation can lead to enhanced user experiences, higher customer satisfaction, and increased ROI for your business."
                }
                },{
                "@type": "Question",
                "name": "Does your AI design agency have experience in our industry niche?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, our AI design agency has experience working with clients across various industry niches, including e-commerce, healthcare, finance, technology, and more. 
            We tailor our AI design solutions to meet the unique challenges and requirements of each industry, delivering impactful results that align with your business objectives."
                }
                },{
                "@type": "Question",
                "name": "What sets your AI design agency apart from traditional design firms?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Unlike traditional design firms, our AI design agency specializes in harnessing the power of artificial intelligence to create intelligent and dynamic website experiences.
            We combine human creativity with AI-driven insights to deliver innovative solutions that drive engagement and achieve business objectives effectively."
                }
                },{
                "@type": "Question",
                "name": "Can you provide examples of successfully completed AI projects?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Certainly! Our AI design agency has a portfolio of successful projects across various industries, showcasing our expertise in AI website design, AI-driven user experiences, and AI-powered marketing solutions. 
            We'd be happy to share case studies and examples of our work upon request. Contact Us"
                }
                }]
            }                                                 
        `}
      </script>
    </Helmet>
    <Layout hire="AI Design Agency" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Hire Conversion Rate Optimisation" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} des={''} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="AI Design Agency | AI Website Design"
    description="Our AI design agency leverages advanced AI technologies to craft captivating, industry-leading designs that amplify your brand in the dynamic AI marketplace."
  />
);
