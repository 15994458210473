
import React from 'react';
import Stars from '../../images/Star';
import { StaticImage } from "gatsby-plugin-image";

const OurWorkslef = ({data, hire}) => {
    return (
        <section className={`lg:py-[200px] lg:pt-[150px] md:py-[120px] py-[80px] bg-[#F4F6F6]`}>
            <div className="container">
                <h2 className="lg:mb-[80px] md:mb-[60px] mb-[40px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] lg:w-[820px]" dangerouslySetInnerHTML={{ __html: data.title }} />
                <div className='flex flex-col lg:flex-row gap-[40px]'>
                    <div className='flex flex-col lg:gap-[40px] md:gap-[30px] gap-[20px] w-full lg:min-w-[calc(50%-20px)]'>
                        {
                            data.list1.map((item, index) => (
                                <div className='flex flex-col lg:gap-[40px] md:gap-[30px] gap-[25px] w-full lg:p-[50px] md:p-[40px] p-[30px] rounded-[2px] bg-[#FFF]' key={index}>
                                    <div className=''>
                                    {hire ? (
                                        index === 1 ? (
                                            <StaticImage
                                                src='../../images/adani.webp'
                                                alt='adani'
                                                title='adani'
                                                class='w-[68px]'
                                            />
                                        ) : index === 2 ? (
                                            <StaticImage
                                                src='../../images/nirvana.webp'
                                                alt='adani'
                                                title='adani'
                                                class='w-[129px]'
                                            />
                                        ) : (
                                            <StaticImage
                                                src='../../images/intellect.webp'
                                                alt='intellect'
                                                title='intellect'
                                                class='w-[85.1px]'
                                            />
                                        )
                                    ) : (
                                        index === 1 ? (
                                            <StaticImage
                                                src='../../images/intellect.webp'
                                                alt='intellect'
                                                title='intellect'
                                                class='w-[85.1px]'
                                            />
                                        ) : index === 2 ? (
                                            <StaticImage
                                                src='../../images/nirvana.webp'
                                                alt='adani'
                                                title='adani'
                                                class='w-[129px]'
                                            />
                                        ) : (
                                            <StaticImage
                                                src='../../images/ship-delight.webp'
                                                alt='intellect'
                                                title='intellect'
                                                class='w-[151px]'
                                            />
                                        )
                                    )}


                                    </div>
                                    <div className='flex items-center gap-[4px]'>
                                        {Array.from({ length: item.stars }).map((_, index) => (
                                            <Stars key={index} />
                                        ))}
                                        {Array.from({ length: 5 - item.stars }).map((_, index) => (
                                            <Stars fill="#D6DEDC" key={index} />
                                        ))}
                                    </div>
                                    <p className='text-[18px] md:text-[20px] lg:text-[24px] leading-[1.5] font-medium tracking-[.02em]'>{item.text}</p>
                                    <div>
                                        <p className='text-[14px] md:text-[16px] lg:text-[18px] font-heading leading-[normal] font-semibold'>{item.name}</p>
                                        <p className='text-[12px] md:text-[14px] leading-[normal] tracking-[.05em]'>{item.des}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='flex flex-col gap-[40px] min-w-[calc(50%-20px)]'>
                        {
                            data.list2.map((item, index) => (
                                <div className='flex flex-col lg:gap-[40px] md:gap-[30px] gap-[25px] w-full lg:p-[50px] md:p-[40px] p-[30px] rounded-[2px] bg-[#FFF]' key={index}>
                                    <div className=''>
                                        {hire ? (
                                            index === 1 ? (
                                                <StaticImage
                                                    src='../../images/torfac.webp'
                                                    alt='intellect'
                                                    title='intellect'
                                                    class='w-[92px]'
                                                />
                                            ) : index === 2 ? (
                                                <StaticImage
                                                    src='../../images/ship-delight.webp'
                                                    alt='intellect'
                                                    title='intellect'
                                                    class='w-[151px]'
                                                />
                                            ) : (
                                                <StaticImage
                                                    src='../../images/visa.webp'
                                                    alt='visa'
                                                    title='visa'
                                                    className='w-[68px]'
                                                />
                                            )
                                            ) : (
                                                index === 1 ? (
                                                    <StaticImage
                                                        src='../../images/visa.webp'
                                                        alt='visa'
                                                        title='visa'
                                                        className='w-[68px]'
                                                    />
                                                ) : index === 2 ? (
                                                    <StaticImage
                                                        src='../../images/adani.webp'
                                                        alt='adani'
                                                        title='adani'
                                                        class='w-[68px]'
                                                    />
                                                ) : (
                                                    <StaticImage
                                                        src='../../images/torfac.webp'
                                                        alt='intellect'
                                                        title='intellect'
                                                        class='w-[92px]'
                                                    />
                                                )
                                            )}
                                    </div>
                                    <div className='flex items-center gap-[4px]'>
                                        {Array.from({ length: item.stars }).map((_, index) => (
                                            <Stars key={index} />
                                        ))}
                                        {Array.from({ length: 5 - item.stars }).map((_, index) => (
                                            <Stars fill="#D6DEDC" key={index} />
                                        ))}
                                    </div>
                                    <p className='text-[18px] md:text-[20px] lg:text-[24px] leading-[1.5] font-medium tracking-[.02em]'>{item.text}</p>
                                    <div>
                                        <p className='text-[14px] md:text-[16px] lg:text-[18px] font-heading leading-[normal] font-semibold'>{item.name}</p>
                                        <p className='text-[12px] md:text-[14px] leading-[normal] tracking-[.05em]'>{item.des}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurWorkslef
